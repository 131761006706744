import Head from 'next/head'
import Link from 'next/link'
import { useContext, useEffect } from 'react'
import { Navigation } from '@/components/NavigationContext'
import { gsap } from 'gsap'

const Home = ({ content, navigation }) => {
	const { setNavigationItems } = useContext(Navigation)

	useEffect(() => {
		if (setNavigationItems) {
			setNavigationItems(navigation)
		}
	}, [setNavigationItems])

	return (
		<>
			<Head>
				<title>Studio Rinsma | Creative designer & photographer</title>
				<meta name="description" content="Hi, I'm Kevin. A freelance graphic designer and photographer" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="icon" href="/favicon.ico" />
			</Head>

			<div className="page-home">
				<header>
					<div
						className="header-background"
						style={{
							backgroundImage: `url(${content.homepageHeader.backgroundImage.url})`,
							backgroundSize: `${content.homepageHeader.backgroundSize}%`,
						}} 
					/>
					<div className="global-wrapper">
						<div className="header-inner">
							<h1>
								<span className="heading-js-wrapper">
									<span style={{ animationDelay: '.2s' }} dangerouslySetInnerHTML={{__html: content.homepageHeader.title}} />
								</span>
							</h1>
							
							<p>
								<span className="heading-js-wrapper">
									<span style={{ animationDelay: '.6s' }}>{content.homepageHeader.subtitle}</span>
								</span>
							</p>

							<div
								onClick={() => {
									gsap.to(window, { duration: .5, scrollTo: { y: '#projects', offsetY: 100 } })
								}}
								className="scroll-down"
							>
								<span className="link-text">{content.homepageHeader.ctaText}</span>
								<i className="fas fa-arrow-right-long" />
							</div>

							<div className="header-links">
								<ul>
									{content.homepageSocialMediaLinks.map((item, index) => (
										<li key={index}>
											<a href={item.link} target="_blank" rel="nofollow noopener noreferrer">
												{item.title}
											</a>
										</li>
									))}
								</ul>
							</div>

							<div className="cta">
								<i className="fas fa-arrow-left-long" />
								<span>{content.homepageHeader.scrollDownText}</span>
							</div>
						</div>
					</div>
				</header>

				<section className="projects" id="projects">
					<div className="section-header">
						<div className="global-wrapper">
							<div className="section-header-inner">
								<h2>{content.homepageProjectsSection.title}</h2>

								<Link href={content.homepageProjectsSection.link}>
									<span>View all</span>
									<i className="fas fa-arrow-right-long" />
								</Link>
							</div>
						</div>
					</div>
					
					<div className="projects-wrapper">
						{content.homepageProjectsSection.preview.map((project, index) => (
							<div className="project" key={index}>
								<Link href={`/project/${project.slug}`}>
									<div className="global-wrapper">
										<div className="project-inner">
											<span className="counter">{((index + 1) < 10) ? `0${index + 1}` : `${index + 1}`}</span>

											<div className="project-info">
												<span className="project-title">{project.title}</span>
												<span className="project-type">{project.categories.slice(0, 2).join(' & ')}</span>
											</div>

											<div className="link-wrapper">
												<span>View project</span>
												<i className="fas fa-arrow-right-long" />
											</div>
										</div>
									</div>
								</Link>
							</div>
						))}
					</div>
				</section>

				<section className="photography">
					<div className="section-header">
						<div className="global-wrapper">
							<div className="section-header-inner">
								<h2>{content.homepagePhotographySection.title}</h2>

								<Link href={content.homepagePhotographySection.link}>
									<span>View all</span>
									<i className="fas fa-arrow-right-long" />
								</Link>
							</div>
						</div>
					</div>

					<div className="images-wrapper">
						{content.homepagePhotographySection.preview.map((image, index) => (
							<div className="image" key={index}>
								<img src={image.url} alt={image.title} />
							</div>
						))}
					</div>
				</section>

				<section className="cta-wrapper">
					<div className="global-wrapper">
						<Link href={`/${content.cta.link}`} className="cta-inner">
							<div className="cta-content">
								<span className="cta-title">{content.cta.title}</span>
								<span className="cta-subtext">{content.cta.subtext}</span>
							</div>

							<div className="cta-link-wrapper">
								<i className="fas fa-arrow-right-long" />
							</div>
						</Link>
					</div>
				</section>
			</div>
		</>
	)
}

export default Home

export async function getServerSideProps() {
	const res = await fetch(`${process.env.API_URL}/page/home.json`)
	const content = await res.json()

	const navigationRes = await fetch(`${process.env.API_URL}/api/globals.json`)
	const navigation = await navigationRes.json()

	return {
		props: {
			content,
			navigation,
		}
	}
}